<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <app-title title="Estadisticas" subtitle="Estadisticas de ventas, produccion, visualizaciones, etc." />
      <v-layout wrap class="px-2">
        <v-flex v-for="option in options" :key="option.name" xs4>
          <v-card>
            <v-card-title primary-title>
              <div>
                <div class="headline">{{ option.title }}</div>
                <span>{{ option.description }}</span>
              </div>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat :to="{ name: 'storeStatisticsID', params: { id: option.url } }">Revisar</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import appTitle from '../../useful/title.vue'
import { MI_STORE } from '../../../config'

export default {
  name: 'StoreStatistics',
  components: { appTitle },
  data() {
    return {
      options: [
        {
          title: 'Productos',
          description: 'Variacion precios entre productos por un rango de fechas',
          url: 'productos',
          value: 1
        },
        {
          title: 'Pedidos Entregados',
          description: 'Resumen con el monto total y cantidad de entregas realizadas.',
          url: 'pedidos-entregados',
          value: 2
        },
        {
          title: 'Repartidores',
          description: 'Cuantas entregas realiza en un rango de tiempo y resumen de pedidos',
          url: 'repartidores',
          value: 4
        },
        {
          title: 'Horario de entrega',
          description: 'Resumen de entregas comparando horas de entregas',
          url: 'horarios-de-entrega',
          value: 5
        },
        {
          title: 'Diseñadores',
          url: 'disenadores',
          description: 'Cuantos productos elaborados en un rango de tiempo y resumen de pedidos',
          value: 6
        },
        {
          title: 'Cupones',
          url: 'cupones',
          description: 'Resumen uso de cupones de acuerdo a un rango de fechas',
          value: 7
        }
      ],
      filterStatus: [],
      dealers: [],
      designers: [],
      dealer: {},
      designer: {},
      dateStart: this.$moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
      dateEnd: this.$moment().format('YYYY-MM-DD'),
      loading: false,
      type: 0,
      items: [{ id: 1, title: 'Productos - Variacion precios' }],
      headersProductsPrice: [
        { text: '', align: 'left', sortable: false },
        { text: 'Nombre', align: 'left', sortable: false },
        { text: 'Normal', value: 'normalPrice', sortable: true },
        { text: 'Alta Demanda', value: 'highPrice', sortable: true },
        { text: 'Variacion', value: 'variacion', sortable: true },
        { text: 'Tiempo', value: 'time', sortable: true },
        { text: '', sortable: false }
      ],
      rows: [],
      rowsActive: [],
      rowsInactive: [],
      rowsAll: [],
      discounts: [],
      pagination: {
        sortBy: 'variacion',
        rowsPerPage: 50
      },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      totalRow: 0,
      visible: false,
      visibleDiscount: false,
      groupRows: [],
      dateUse: 'fecha_entrega'
    }
  },
  computed: {
    retrasos() {
      if (this.type === 5) {
        return this._.filter(this.rowsAll, ['retraso', true]).length
      }
      return 0
    },
    perfectos() {
      if (this.type === 5) {
        return this._.filter(this.rowsAll, ['retraso', false]).length
      }
      return 0
    },
    tiempoPromedio() {
      if (this.type === 4) {
        return this._.sumBy(this.rows, 'diff') / this.rows.length
      }
      return 0
    },
    montoTotal() {
      if (this.type === 4) {
        return this._.sumBy(this.rows, 'valor_despacho')
      }
      return this._.sumBy(this.rows, 'valor')
    },
    descuentoTotal() {
      return this._.sumBy(this.discounts, 'value')
    },
    porFinalizar() {
      const sum = this._.sumBy(this.rowsInactive, e => {
        return e.product.cantidad
      })
      return sum
    },
    finalizados() {
      const sum = this._.sumBy(this.rowsActive, e => {
        return e.product.cantidad
      })
      return sum
    },
    finalizadosMonto() {
      const sum = this._.sumBy(this.rowsActive, e => {
        return e.product.cantidad * e.product.valor_total_pedido
      })
      return sum
    }
  },
  watch: {
    pagination() {},
    type(e) {
      this.rows = []
      if (e !== 4) {
        this.getProductsPrice()
      }
      if (e === 4) {
        this.getDealers()
      }
      if (e === 6) {
        this.getDesigner()
      }
    },
    filterStatus(e) {
      this.filterItems(e)
    }
  },
  methods: {
    async getProductsPrice() {
      this.loading = true
      try {
        const res = await this.$http(
          `${MI_STORE}/statistics/cupones?${this.$qs.stringify({
            type: this.type,
            filter: {
              dateStart: this.dateStart,
              dateEnd: this.dateEnd,
              dateUse: this.dateUse
            }
          })}`
        )
        if (this.type === 1) {
          this.loading = false
          this.rows = res.data
          this.rowsAll = res.data
        } else if (this.type === 2) {
          this.rowsAll = res.data.orders
          this.rows = res.data.orders
          this.groupRows = res.data.group
        } else if (this.type === 3) {
          this.rows = res.data.orders
          this.discounts = res.data.discount
        } else if (this.type === 5) {
          this.rows = res.data.orders
          this.rowsAll = res.data.orders
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getProductsPrice() {
      this.loading = true
      try {
        const res = await this.$http(
          `${MI_STORE}/statistics/price?${this.$qs.stringify({
            type: this.type,
            filter: {
              dateStart: this.dateStart,
              dateEnd: this.dateEnd,
              dateUse: this.dateUse
            }
          })}`
        )
        if (this.type === 1) {
          this.loading = false
          this.rows = res.data
          this.rowsAll = res.data
        } else if (this.type === 2) {
          this.rowsAll = res.data.orders
          this.rows = res.data.orders
          this.groupRows = res.data.group
        } else if (this.type === 3) {
          this.rows = res.data.orders
          this.discounts = res.data.discount
        } else if (this.type === 5) {
          this.rows = res.data.orders
          this.rowsAll = res.data.orders
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getDealerPrices() {
      try {
        const res = await this.$http.get(
          `${MI_STORE}/statistics/dealer?${this.$qs.stringify({
            filter: {
              dateStart: this.dateStart,
              dateEnd: this.dateEnd
            },
            dealer: this.dealer
          })}`
        )
        this.rows = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getDesignerPrices() {
      try {
        const res = await this.$http.get(
          `${MI_STORE}/statistics/designer?${this.$qs.stringify({
            filter: {
              dateStart: this.dateStart,
              dateEnd: this.dateEnd
            },
            designer: this.designer
          })}`
        )
        this.rowsActive = this._.filter(res.data, ['type', 1])
        this.rowsInactive = this._.filter(res.data, ['type', 0])
        this.rowsAll = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getDealers() {
      try {
        const res = await this.$http(`${MI_STORE}/dealers`)
        this.dealers = this._.filter(res.data, ['status', true])
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getDesigner() {
      try {
        const res = await this.$http(`${MI_STORE}/designers`)
        this.designers = this._.filter(res.data, ['status', true])
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    filterPriceProducts(e) {
      console.log(e)
      this.rows = this._.filter(this.rowsAll, ['forma_de_pago', e])
      this.visible = true
    },
    filterOrder(e) {
      if (this.type === 5 && e === 1) {
        this.rows = this._.filter(this.rowsAll, ['retraso', true])
      } else if (this.type === 5 && e === 2) {
        this.rows = this._.filter(this.rowsAll, ['retraso', false])
      } else if (this.type === 5 && e === 3) {
        this.rows = this.rowsAll
      }
    },
    filterItems(e) {
      const activo = this._.indexOf(e, 0)
      const inactivo = this._.indexOf(e, 1)
      this.rows = this._.filter(this.rowsAll, o => {
        if (activo >= 0 && inactivo < 0) {
          return o.status
        }
        if (inactivo >= 0 && activo < 0) {
          return !o.status
        }
        return true
      })
    },
    minutesSecons(seconds) {
      return this.$moment.utc(seconds * 1000).format('HH:mm:ss')
    },
    colorProductsVariation(value) {
      if (value <= 0) {
        return 'error'
      }
      return ''
    },
    colorProductsVariationText(value) {
      if (value <= 0) {
        return 'white'
      }
      return 'black'
    }
  }
}
</script>
